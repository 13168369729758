
import axios from 'axios';
export default {
    async employeeReport(params) {
        return await axios.post(`reports/employee`, params)
    },
    async allEmployeeReport(params) {
        return await axios.post(`reports/allEmployee`, params)
    },
    async reportOrderItems(params) {
        return await axios.post(`reports/orderItems`, params)
    },
    async itemByMonthReport() {
        return await axios.get(`reports/itemByMonth`)
    },
    async moneyByMonthReport(params) {
        return await axios.post(`reports/moneyByMonth`, params)
    },
    async depositsReport(params) {
        return await axios.post(`reports/deposits`, params)
    },
    async withdrawReport(params) {
        return await axios.post(`reports/withdraws`, params)
    },
    async studentSubjectsReport(params) {
        return await axios.post(`reports/studentSubjects`, params)
    },
    async allAccounts(params) {
        return await axios.post(`reports/allAccounts`, params)
    },
    async allInstructors(params) {
        return await axios.post(`reports/allInstructors`, params)
    },
    async allStudents(params) {
        return await axios.post(`reports/allStudents`, params)
    } 
    
}
