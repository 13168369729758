<template>
	<div>
		<v-container class="no-print">
			<v-layout row wrap>
				<h1 class="my-7">
					{{$store.getters.language.data.report.title}}
				</h1>
			</v-layout>
			<v-layout row wrap>
				<v-flex xs12 lg3 xl3 md4>
					<v-text-field label="From" type="date" dense outlined class="mx-1" v-model="query.from"></v-text-field>
				</v-flex>
				<v-flex xs12 lg3 xl3 md4>
					<v-text-field label="To" type="date" dense outlined class="mx-1" v-model="query.to"></v-text-field>
				</v-flex>
				<!-- autocomplete employee -->
				<v-flex xs12 lg3 xl3 md4>
					<v-autocomplete v-model="query.employee_id" :items="employees" :label="$store.getters.language.data.employees.employee_name" outlined dense item-text="employee_name" item-value="employee_id" class="mx-1"></v-autocomplete>
				</v-flex>

			</v-layout>
			<v-layout row wrap>
				<v-flex xs12 lg3 md4 xl3 v-for="(item,index) in reports_list" :key="index">
					<v-card class="ma-1" @click="openReport(item.id)">
						<v-card-title>{{item.title}}</v-card-title>
						<v-card-text>
							<span class="primary--text" v-for="(i,inx) in splitString(item.query)" :key="inx">{{i}}*</span>
							<br>
							<span v-for="(i,inx) in splitString(item.optional)" :key="inx">{{i}}</span>
						</v-card-text>
					</v-card>
				</v-flex>
			</v-layout>
			<v-flex xs12 lg12 sm12 xl12 class="my-5">
				<v-btn color="primary" @click="print">
					<!-- {{$store.getters.language.data.report.print}} -->
					Print
				</v-btn>
			</v-flex>
			<v-layout row wrap>
				<v-progress-linear v-if="loading" :indeterminate="true" color="primary" height="5"></v-progress-linear>
			</v-layout>
		</v-container>
		<EmployeeReport :query="query" v-if="selectedReport == 1 && !loading"></EmployeeReport>
        <AllEmoloyees :query="query" v-if="selectedReport == 2 && !loading"></AllEmoloyees>
	</div>
</template>

<script>

	import requests from '../../requests/employees.request'
    import EmployeeReport from './Employee.vue'
    import AllEmoloyees from './AllEmployees.vue'
	export default {
		components: {
         EmployeeReport,
            AllEmoloyees
		},
		data() {
			return {

				query: {
					from: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
					to: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
				},
				id: '',
				loading: false,
				selectedReport: 0,
				snackbar: {
					value: false,
					text: '',
					color: ''
				},
				employees: [],
			}
		},
		mounted() {
			this.readEmployees()
		},
		computed: {

			reports_list() {
				return this.$store.getters.language.data.report.reports_list
			},

		},
		methods: {
			readEmployees() {
				this.loading = true
				requests.getAllEmployees().then(r => {
					if (r.status == 200) {
						this.employees = r.data.rows
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read Employees',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read Employees',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},


			print() {
				const theme = this.$vuetify.theme.isDark
				this.$vuetify.theme.isDark = false
				setTimeout(() => {
					window.print()
					this.$vuetify.theme.isDark = theme

				}, 1000)
			},
			openReport(id) {
				this.loading = true
				this.selectedReport = id
				setTimeout(() => {
					this.selectedReport = id
					this.loading = false
				}, 1000);
			},
			splitString(str) {
				return str ? str.split(',') : []
			}
		},
	}

</script>

<style>
</style>