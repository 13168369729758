<template>
	<div>
		<v-container px-1>

			<v-layout row wrap v-if="employee.employee_id" class="mx-0">

				<v-flex xs12 lg6 xl6 md6 sm4>
					<h3>
						Name: {{employee.employee_name}}
					</h3>
					<h3>
						Base Salary: {{(employee.employee_base_salary).toLocaleString()}}
					</h3>
					<h3>
						Food Budget: {{(employee.employee_food_budget).toLocaleString()}}
					</h3>
					<h3>
						Transportation Budget: {{(employee.employee_transportation_budget).toLocaleString()}}
					</h3>
					<h3>
						Phone Budget: {{(employee.employee_phone_budget).toLocaleString()}}
					</h3>
					<h3>
						Driver Budget: {{(employee.employee_driver_budget).toLocaleString()}}
					</h3>
					<h3>
						Government Insurance: {{(employee.employee_government_insurance).toLocaleString()}}
					</h3>
					<h3>
						Company Insurance: {{employee.employee_insurance_rate}}
					</h3>

				</v-flex>
			</v-layout>
			<v-divider class="my-4"></v-divider>
			<h4>
                <!-- report date from to  -->
             {{query.from}} - {{query.to}}
            </h4>
            <v-divider class="my-4"></v-divider>

			<h2 class="my-2">
				{{$store.getters.language.data.extra_hours.title}}
			</h2>
			<v-layout>
				<table class="report-table" style="width:100%" border="1">
					<thead>
						<tr>
							<th>#</th>

							<th>
								{{$store.getters.language.data.employees.employee_name}}
							</th>
							<th>
								{{$store.getters.language.data.extra_hours.extra_hour_total}}
							</th>
							<!-- <th>
								{{$store.getters.language.data.extra_hours.extra_hour_note}}
							</th> -->
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item,index) in extra_hours" :key="index">
							<td>{{index+1}}</td>
							<td>
								{{item.employee_name}}
							</td>
							<td>{{item.total_extra_hours}}</td>
							<!-- <td>
								{{item.extra_hour_note}}
							</td> -->

						</tr>
					</tbody>
				</table>
			</v-layout>
			<h2 class="my-2">
				{{$store.getters.language.data.off_days.title}}
			</h2>
			<v-layout>
				<table class="report-table" style="width:100%" border="1">
					<thead>
						<tr>
							<th>#</th>

							<th>
								{{$store.getters.language.data.employees.employee_name}}
							</th>
							<th>
								{{$store.getters.language.data.off_days.off_day_total}}
							</th>
							<!-- <th>
								{{$store.getters.language.data.off_days.off_day_note}}
							</th> -->
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item,index) in off_days" :key="index">
							<td>{{index+1}}</td>
							<td>
								{{item.employee_name}}
							</td>
							<td>{{item.total_off_days}}</td>
							<!-- <td>
								{{item.off_days_count}}
							</td> -->

						</tr>
					</tbody>
				</table>
			</v-layout>
			<h2 class="my-2">
				{{$store.getters.language.data.iligal_days.title}}
			</h2>
			<v-layout>
				<table class="report-table" style="width:100%" border="1">
					<thead>
						<tr>
							<th>#</th>

							<th>
								{{$store.getters.language.data.employees.employee_name}}
							</th>
							<th>
								{{$store.getters.language.data.iligal_days.iligal_day_total}}
							</th>
							<!-- <th>
								{{$store.getters.language.data.iligal_days.iligal_day_note}}
							</th> -->
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item,index) in iligal_days" :key="index">
							<td>{{index+1}}</td>
							<td>
								{{item.employee_name}}
							</td>
							<td>{{item.total_iligal_days}}</td>
							<!-- <td>
								{{item.iligal_day_note}}
							</td> -->

						</tr>
					</tbody>
				</table>
			</v-layout>
			<h2 class="my-2">
				{{$store.getters.language.data.fines.title}}
			</h2>
			<v-layout>
				<table class="report-table" style="width:100%" border="1">
					<thead>
						<tr>
							<th>#</th>

							<th>
								{{$store.getters.language.data.employees.employee_name}}
							</th>
							<th>
								{{$store.getters.language.data.fines.fine_count}}
							</th>
							<th>
								{{$store.getters.language.data.fines.fine_total}}
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item,index) in fines" :key="index">
							<td>{{index+1}}</td>
						
							<td>{{item.employee_name}}</td>
							<td>
								{{item.fines_count}}
							</td>
							<td>
								{{(item.total_fines).toLocaleString()}}
							</td>

						</tr>
					</tbody>
				</table>
			</v-layout>
            	<h2 class="my-2">
				{{$store.getters.language.data.insurances.title}}
			</h2>
			<v-layout>
				<table class="report-table" style="width:100%" border="1">
					<thead>
						<tr>
							<th>#</th>

							<th>
								{{$store.getters.language.data.employees.employee_name}}
							</th>
							<th>
								<!-- {{$store.getters.language.data.insurances.insurance_totals}} -->
                                Insurance Total
							</th>
							
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item,index) in insurance_totals" :key="index">
							<td>{{index+1}}</td>
						
							<td>{{item.employee_name}}</td>
							
							<td>
								{{(item.final_amount).toLocaleString()}}
							</td>

						</tr>
					</tbody>
				</table>
			</v-layout>
		</v-container>
	</div>
</template>
<script>
	import requests from '../../requests/employeereports.requests'
	export default {
		data() {
			return {
				rows: [],
				employee: {},
				extra_hours: [],
				fines: [],
				iligal_days: [],
                insurance_totals: [],
				off_days: [],
				loading: false,
			}
		},
		props: {
			query: {
				type: Object,
				default: () => { }
			}
		},
		mounted() {
			this.openReport()
		},
		methods: {
			openReport() {
				this.loading = true
				let query = this.$props.query
                if (query.employee_id) {
                    delete query.employee_id
                }
				requests.allEmployeeReport(query).then(res => {
					// this.rows = res.data.rows
					// this.employee = res.data.rows[0]
					this.extra_hours = res.data.totalextrahours
					this.fines = res.data.totalfine
					this.iligal_days = res.data.totaliligaldays
					this.off_days = res.data.totaloffdays
                    this.insurance_totals = res.data.insurance_totals
					this.loading = false
				})
			}
		},
	}
</script>